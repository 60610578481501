import _ from "lodash";

export default {
	name: 'form-color',
	props: ['value', 'label', 'rules', 'required', 'customProps'],
	
	data() {
		return {
			prefix: "#",

			errors: [],
			rgbValidationRules: [
				v => (v.length === 6) || "Wrong rgb format (e.g. #FFFFFF)",
				...(this.rules || [])
			],
			color: this.value || "#FFFFFF"
		}
	},
	created() {
		if (this.customProps && this.customProps.prefix) this.prefix = this.customProps.prefix;
		this.validate(this.rgbValidationRules, this.textValue);
	},
	methods: {
        async onEdit(val) {
			await this.validate(this.rgbValidationRules, val);

            this.$emit('input', `${this.prefix}${val}`);
		},
		
		async validate(rules, value) {
			this.errors = [];

			await Promise.all(
				_.map(rules, async (validator) => {
					const result = await validator(value);
					if (result !== true) this.errors.push(result)
				})
			);
			
			const isValid = (this.errors.length === 0);
			this.$emit("valid", isValid);
		},
		setColor(event) {
			this.color = event.target.value;

			this.$emit('input', `${this.color}`);
		}
	},
	computed: {
		textValue() {
			return (this.color || "").replace(this.prefix, '');
		}
	}
};
